import "./App.css";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Toaster } from "react-hot-toast";
import { Spin } from "antd";

// Configure Cognito
import { I18n, Hub, Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AMPLIFY_LOCALE_BE_NL } from "./auth/authTranslations";

// Import custom components
import NavBar from "./components/Shared/NavBar";
import Home from "./components/Home/Home";
import AuthenticationContext from "./auth/AuthenticationContext";
import ButtonPrimary from "./components/Shared/Button.Primary";

// Import Routes
import ProtectedRoute from "./Routes/ProtectedRoute";
import AdminRoute from "./Routes/Admin.Route";
import UploadRoute from "./Routes/Upload.Route";
import FuzTypeRoute from "./Routes/FuzType.Route";

// Import Domain
import { UserDTO } from "./domain/User/User.DTO";
import { FuzTypeDTO } from "./domain/FuzType/FuzType.DTO";
import { FuzUploadDTO } from "./domain/FuzType/FuzUpload.DTO";

// Import Services
import AuthHelper from "./auth/AuthHelper";
import UserService from "./domain/User/User.Service";
import FuzAllocationService from "./domain/FuzAllocation/FuzAllocation.Service";
import FuzUploadService from "./domain/FuzType/FuzUpload.Service";

// Configure Services
const authHelper = new AuthHelper();
const userService = new UserService();
const fuzAllocationService = new FuzAllocationService();
const fuzUploadService = new FuzUploadService();

require("dotenv").config();

// Translate Cognito components
I18n.setLanguage("nl");
I18n.putVocabulariesForLanguage(
  "nl",
  JSON.parse(JSON.stringify(AMPLIFY_LOCALE_BE_NL))
);

function App() {
  const [userInfo, setUserInfo] = useState<UserDTO>();
  const [fuzTypes, setFuzTypes] = useState<FuzTypeDTO[]>([]);
  const [userUploads, setUserUploads] = useState<FuzUploadDTO[]>([]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          getUserInfo();
          break;
        case "signOut":
          setUserInfo(undefined);
          setFuzTypes([]);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    const getUserInfo = async () => {
      const authInfo = await authHelper.getAuthInfo();
      console.log("AuthInfo: ", authInfo);
      const userInfo = await userService.getUser(authInfo.username);
      console.log("UserInfo:", userInfo);
      setUserInfo(userInfo);

      const fuzTypeData: FuzTypeDTO[] =
        await fuzAllocationService.getFuzTypesFromTeams(userInfo.Teams);
      // console.log("Fuz Type Data:", fuzTypeData)
      setFuzTypes(fuzTypeData);

      const userUploads: FuzUploadDTO[] =
        await fuzUploadService.getUploadsForUser(authInfo.username);
      // console.table(UserUploads)
      setUserUploads(userUploads);
    };
    getUserInfo();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <AmplifyAuthenticator>
          <AmplifySignIn
            slot="sign-in"
            hideSignUp
            headerText="Vlaams Agentschap Zorg & Gezondgheid"
          >
            <div slot="federated-buttons" />
            <div slot="header-subtitle">
              <button
                onClick={() =>
                  Auth.federatedSignIn({ customProvider: "vo-fuz" })
                }
              >
                Login met VO Gebruikersbeheer
              </button>
              {/* <button
                onClick={() =>
                  Auth.federatedSignIn({ customProvider: "Gebruikersbeheer" })
                }
              >
                VO USER
              </button> */}
              {process.env.REACT_APP_ENV === "dev" ? (
                <h2>DEV - Upload Zone</h2>
              ) : process.env.REACT_APP_ENV === "uat" ? (
                <h2> UAT - Upload Zone</h2>
              ) : (
                <h2>Upload Zone</h2>
              )}
              <p>Log in om te kunnen uploaden.</p>
              {/* <p>Indien uw geen toegang heeft kan u toegang aanvragen via het user portaal.</p>
                            <Link to={{ pathname: `${process.env.REACT_APP_USER_MANAGEMENT_WEB_URL}/accessrequest` }} target="_blank">
                                <ButtonPrimary text="Vraag Toegang aan" />
                            </Link> */}
            </div>
          </AmplifySignIn>
          <Switch>
            <AuthenticationContext>
              <div className="App">
                <NavBar title="Vlaams Agentschap Zorg en Gezondheid" />
                {userInfo ? (
                  <div>
                    <Route path="/upload" exact>
                      <UploadRoute
                        fuzTypes={fuzTypes}
                        userInfo={userInfo}
                        userUploads={userUploads}
                      />
                    </Route>
                    <ProtectedRoute
                      path="/admin"
                      exact
                      component={AdminRoute}
                      redirectPath="/"
                      allowedFor={["Admin"]}
                    />
                    <ProtectedRoute
                      path="/admin/fuztype/:id"
                      redirectPath="/"
                      allowedFor={["Admin"]}
                    >
                      <FuzTypeRoute />
                    </ProtectedRoute>
                    <Route path="/" exact>
                      <Home />
                    </Route>
                  </div>
                ) : (
                  <Spin />
                )}
              </div>
            </AuthenticationContext>
          </Switch>
          <Toaster />
        </AmplifyAuthenticator>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
